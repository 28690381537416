import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Contactimg from "../assets/dronepic.jpg";
import DestinationData from "../components/DestinationData";
import Footer from "../components/Footer";
import Blogimg from "../assets/blogpic.jpeg";
import Whatsapp from "../components/Whatsapp";
import "../components/DestinationStyles.css";
import Card from "../components/Card";
import l1 from "../assets/klogo1.jpg";
import l2 from "../assets/DGPS2.jpg";
import l3 from "../assets/drone1.jpg";
import l4 from "../assets/ts1.jpg";
import l5 from "../assets/ppk1.jpg";

function Blog() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroimg="https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
        title="Blogs"
        btnClass="hide"
      />
        <div className="destination">
        
        <Card
      title="PROFESSIONAL LAND SURVEYING : KRAAS (P) Ltd. "
      description="We are land surveying firm, providing services across the Maharashtra.

      We specialize in construction layouts, topographical surveys, boundaries, subdivision design, title and boundary surveys, pipelines, unit surveys, digitizing, map reproduction and preparation, contour surceyand provide dedicated mapping services.
      
      Professional Land Surveying Services....read more
      "
      image={l1}
      link="/blog/PLS"
      />
        <Card
      title="KRAAS DGPS Survey"
      description="A precise land survey is the first step of any construction project and the accuracy of this survey will define the precision of the construction going forward.

      Also, there are various methods that surveyors have used for many years e.g. Total Station. So, the survey equipment and technology keep upgrading every year.
      ....read more
      "
      image={l2}
      link="/blog/DGPS"
      />
    
      <Card
      title="KRAAS Drone Surveying
      "
      description="Drones are powerful tools to help in the mapping and surveying industry. They can effectively perform work for 3D mapping, land surveys, photogrammetry, and topographic surveying by flying above the ground. Whether you are looking to add another tool to your services or want to learn more about the world of drones, here is everything that you need to know about drone surveying. 
      read more"
      image={l3}
      link="/blog/DroneSurvey"
     

      />
        <Card
      title="KRAAS Total Station in Surveying
      "
      description="Total station is a surveying equipment combination of Electromagnetic Distance Measuring Instrument and electronic theodolite. It is also integrated with microprocessor, electronic data collector and storage system. The instrument can be used to measure horizontal and vertical angles as well as sloping distance of object to the instrument.

      read more"
      image={l4}
      link="/blog/TotalStation"

      />
        <Card
      title="Post Processed Kinematic (PPK) Survey 
      "
      description="Post processed kinematic (PPK) is a GPS correction technology used in surveying that corrects the location data after it is collected and uploaded.

      Real-time kinematic (RTK) is another type of correction technology like PPK but performs real-time corrections to the data and images. PPK technique is commonly used in drone mapping and land surveying.
      read more"
      
      image={l5}
      link="/blog/PPK"

      />
       
     
    

   
   
        </div>

  
     
      
      <Whatsapp />

      <Footer />
    </>
  );
}

export default Blog;
