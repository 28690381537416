import React from 'react'
import DestinationData from '../components/DestinationData'
import ts1 from '../assets/ts1.jpg';
import ts2 from '../assets/ts2.jpg';
import ts3 from '../assets/ts3.jpg';
import ts4 from '../assets/ts4.jpg';
const TotalStation = () => {
  return (
    <>
    <div className='destination'>
    <DestinationData
          className="first-des"
          heading="KRAAS Total Station in Surveying
      "
          text='A total station is a surveying instrument that combines an electromagnetic distance measuring instrument with an electronic theodolite. It is equipped with a microprocessor, electronic data collector, and storage system. The total station is used to measure horizontal and vertical angles, as well as the sloping distance from the instrument to the object.

          The capabilities of a total station include processing data collected to compute various parameters. This includes averaging multiple measured angles and distances, determining horizontal distances, calculating distances between two points, elevations of objects, and obtaining the three coordinates of observed points.
          
          The data collected and processed by a total station can be downloaded to computers for further analysis and processing. Total stations are compact and lightweight, weighing around 50 to 55 N, making them easily portable to field locations. They are available in different accuracy levels for angle measurements and range of measurements.
          
          Distance measurement is a crucial feature of a total station, and it relies on the electronic distance measuring (EDM) instrument. The range of the EDM varies from 2.8 km to 4.2 km, with measurement accuracy ranging from 5 mm to 10 mm per km. The distance measured is always the sloping distance from the instrument to the object.
          
          For angle measurements, the electronic theodolite component of the total station is used. Horizontal angles can be measured in any convenient reference direction, while vertical angles are measured with the zenith (vertical upward) direction as the reference. The accuracy of angle measurements typically ranges from 2 to 6 seconds.
          '
          img2={ts1} 
          img1={ts2}       
          />
          <DestinationData
          className="first-des"
          text="
          Total stations are equipped with an inbuilt microprocessor for data processing. The microprocessor performs tasks such as averaging multiple observations, computing horizontal distances and X, Y, Z coordinates using slope distance and measured angles, and applying temperature and pressure corrections if atmospheric conditions are provided.

An electronic display unit is present on the total station, capable of showing various values when corresponding keys are pressed. This includes horizontal and vertical distances, horizontal and vertical angles, elevation differences between observed points, and the three coordinates of the observed points.

The total station also features an electronic notebook where point data can be stored. The capacity of the electronic notebook ranges from 2000 to 4000 points. The surveyor can transfer the stored data to a computer for further use and reuse the electronic notebook.

Total stations find applications in various surveying tasks. They are mounted on tripods and leveled using leveling screws. The instrument automatically adjusts itself to the level position within a small range. The surveyor can set the required units for distance, temperature, and pressure and select the measurement mode.

When a target is sighted, the total station measures horizontal and vertical angles, as well as sloping distances. These measurements are recorded along with the point number. The heights of the instrument and targets can be entered manually, and the processor computes various information about the point, displaying it on the screen and storing it in the electronic notebook.

The data collected by the total station can be downloaded to computers for further processing. Software like AutoCAD, Auto Civil, and Auto Plotter can be used for map making, contour plotting at specified intervals, and cross-section plotting along specified lines.

Using a total station offers several advantages over conventional surveying instruments. Fieldwork can be conducted quickly, with high accuracy in measurements. Manual errors in reading and recording are eliminated, and calculations of coordinates are fast and accurate. Computers can be utilized for efficient map making, contour plotting, and easy adjustment of contour intervals and scales.

However, it is important for surveyors to check the working condition of the total station before use. Standard points near the survey office can be used to verify the instrument's accuracy and performance.   " 
         
          img1={ts3}
          img2={ts4}
          />
    </div>
    </>
  )
}

export default TotalStation