import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>KRAAS</h1>
          <p>we make surveys happen</p>
        </div>
        <div>
          <a href="">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="https://twitter.com/KraasPvtltd">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
          <a href="https://www.instagram.com/kraassurveys/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="">
            <i className="fa-brands fa-whatsapp-square"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4></h4>
          <a href="tel:+917709097537">
            <i class="fa-solid fa-phone">{/*jsbccbzcxub*/} 7709097537</i>
          </a>
          <a href="mailto:kraas.pvt.ltd@gmail.com">
            <i class="fa-solid fa-envelope"> </i> kraas.pvt.ltd@gmail.com
          </a>
        </div>
        <div>
          <h4>Contact</h4>

          <a href="https://wa.me/+917709097537" target="_blank">
            <i class="fa-brands fa-whatsapp"></i> 7709097537
          </a>
          
        </div>
        <div>
          <h4>Reg Add.</h4>
          <a href="https://maps.app.goo.gl/jcpFdhmQ7ztHTqPR6" target="_blank">
            <i class="fa-solid fa-location-dot"></i> FINAL PLOT NO.111/3,ANANDGHAN CHS,OPP.BIRMOLE HOSPITAL,PANVEL, DIST. RAIGAD-410206.
          </a>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
