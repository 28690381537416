import Mountain1 from "../assets/1.jpg";
import Mountain2 from "../assets/2.jpg";
import Mountain3 from "../assets/3.jpg";
import Mountain4 from "../assets/4.jpg";
import InvestmentPlot from "../assets/landforsale.jpg";
import PlotPageData from "./PlotPageData";
import "./DestinationStyles.css";
const PlotPage = () => {
  return (
    <>
      <div className="destination">
        <h1> </h1>
        <p> </p>
        <PlotPageData
          className="first-des"
          heading="Investments Plots

      "
          text="We are having comercial residential investment plot Wearhouse plot petrol pump plot also if you are looking for your second home than Kraas is the right option for you as we are having hill view plot and sea view plot also.

          "
          img1={InvestmentPlot}
          button1="Enquire Now"
          // img2={Mountain2}
        />
        <PlotPageData
          className="first-des-reverse"
          heading="Plots For Farmhouse
      "
          text="Best farmhouse plot for sale near panvel at reasonable rates. Take your family to comfortable life near by panvel located farmhouse. farmhouse is a type of a house, which serves a residential purpose in an agricultural setting. It is surrounded by a farm or a well landscaped garden. These can also be called as country houses, away from one's residential address, where one can go to spend some time in the lap of nature."
          img1="https://bhatnagars.co.in/wp-content/uploads/2018/04/IMG-20180418-WA0053.jpg"
          //img2={Mountain4}
        />
        <PlotPageData
          className="first-des"
          heading="Plots For Bungalow
      "
          text="Navi Mumbai is an industrially advanced city in India. Get high returns on your plot investments. In our selection of industrial plots, you can start your own Factories.Kraas pvt ltd has also introduced investment options in industrial plots. You can grow your business by investing in industrial plots."
          img1="https://q-xx.bstatic.com/xdata/images/hotel/840x460/118485929.jpg?k=3a3af9d20fb502bc50c600f5968f30029caceebfc3e747aaf7640a168f3d3579&o="
          // img2={Mountain4}
        />
        <PlotPageData
          className="first-des-reverse"
          heading="Plots For Commercial
      "
          text="Commercial land can be any plot or section of land used for commercial purposes and intended to generate a profit. This means that the land hosts warehouses, industrial property, retail stores, parking lots, malls, hotels, office buildings, and medical centers."
          img1="https://teja12.kuikr.com/is/a/c/880x425/gallery_images/original/cf603be0fe1aa6a.gif"
          //img2={Mountain4}
        />

        <PlotPageData
          className="first-des"
          heading="Plots For Residential
      "
          text="Discover the epitome of luxury and elegance with Kraas' exclusive collection of residential plots. Each plot is thoughtfully selected for its prime location, stunning views, and proximity to essential amenities, ensuring a lifestyle of utmost convenience and comfort. With Kraas, you can envision and create your ideal home, tailored to your unique tastes and desires. Embrace a world of endless possibilities as you embark on a journey towards building your dream residence on our prestigious plots. Don't miss this rare opportunity to secure your future and invest in a plot that promises not just a property, but a legacy to be cherished for generations."
          img1="https://5.imimg.com/data5/ANDROID/Default/2021/9/XH/VR/HE/31577244/img-20210913-wa0022-jpg-500x500.jpg"
          //img2={Mountain4}
        />

        <PlotPageData
          className="first-des-reverse"
          heading="Plots For Warehouse
      "
          text="Wearhouse investment is the most profit gaining investment You can do lots of profit gaining activities over their Providing your land on Renting option will also provide you constant income Also it is open to make your new office , or creative manufacturig idea's.

          "
          img1="https://www.gurgaonapartments.in/admin/upload/property/1545724599_warehouse-land-gurgaon.jpeg"
          //img2={Mountain4}
        />

        <PlotPageData
          className="first-des"
          heading="Plots For Factories
      "
          text="Unleash the full potential of your industrial ambitions with our exceptional selection of plots specifically designed for factories. At Kraas , we understand the critical importance of finding the perfect location for your manufacturing operations. Our meticulously curated plots offer strategic positioning, ample space, and state-of-the-art infrastructure to cater to the unique needs of your factory. Whether you require a spacious area for large-scale production or a specialized zone for niche manufacturing, we have the ideal solution for your business. With our plots, you can benefit from easy accessibility to transportation networks, proximity to key suppliers and markets, and a supportive ecosystem for industrial growth. Embrace the opportunity to establish a thriving manufacturing hub where productivity, efficiency, and success intertwine seamlessly. 

          "
          img1="https://shrm-res.cloudinary.com/image/upload/c_crop,h_710,w_1262,x_0,y_121/w_auto:100,w_1200,q_35,f_auto/v1/Employee%20Relations/iStock-175426710_dteiyx.jpg"
          //img2={Mountain4}
        />

        <PlotPageData
          className="first-des-reverse"
          heading="Plots For Petrolpump
      "
          text="As transportation is totally dependent on fuel and stocks of these fuels need to be available at the right place and the right place is the near by highway. The response from the people is huge so investing in petrol pumps in land is a profitable investment from business purpose also you can start joint business like food court hotels and restaurants.

          "
          img1="https://cdn4.singleinterface.com/files/banner_images/96681/7620_1608711438_12173490.jpg"
          //img2={Mountain4}
        />
        <PlotPageData
          className="first-des"
          heading="Hill View Bungalow
      "
          text="Indian economy is the 70% tourism oriented economy Zaminwale having hill view plots near by Navi Mumbai the best part of this plot is this places are blessed with good greanaray and hill station also if you want to generate income from this than you can build farmhouse & People can planed their weekends for relaxation.

          "
          img1="https://cdn.shopify.com/s/files/1/1808/5743/products/16_4028c22e-9bb8-4712-b249-0c76a62a5fab.jpg?v=1489336551&width=1445"
          //img2={Mountain4}
        />
      </div>
    </>
  );
};

export default PlotPage;
