import React from 'react'
import DestinationData from '../components/DestinationData'
import d1 from '../assets/drone1.jpg';
import d2 from '../assets/dronepic.jpg';
import d3 from '../assets/d3.jpg';
import d4 from '../assets/d4.jpg';
const DroneSurvey = () => {
  return (
    <>
    <div className='destination'>
    <DestinationData
          className="first-des"
          heading="KRAAS Drone Surveying
      "
          text='Drones are powerful tools to help in the mapping and surveying industry. They can effectively perform work for 3D mapping, land surveys, photogrammetry, and topographic surveying by flying above the ground. Whether you are looking to add another tool to your services or want to learn more about the world of drones, here is everything that you need to know about drone surveying. 

          What Is A Drone Survey?
          In general, surveying is the science of determining the distances and positions between two points, whether in a 2D or 3D space. This information is used to help make critical decisions about the upkeep of infrastructure, construction site planning, and delineating property boundaries. When you want to complete a drone survey, you take those basic principles but use a drone to gather the information. The drone will fly above the points and record all of that information for you. 
          
          
          How Does A Drone Survey Work?
          As the drone flies above the ground, it uses downward-facing sensors with multispectral and RGB cameras to capture images. Along with that, many drones feature LiDAR payloads to help collect information. When the drone surveys the lands, it will use the RGB camera to photograph the ground at different angles. Each angle is tagged with the exact coordinates. 
          
          How Do Drones Collect Data?
          Drones collect data from the Geographic Information Systems (GSI). They used this information to visualize and map out locations. The GIS is the primary source for collecting data, but other collection tools can be equipped on the drone. In the past, drones were used to capture but not transmit data. All that information had to be retrieved by the operator. With new technology, drones can interrupt data in real-time. You can turn all that raw data into actionable information through GIS technology while the drone is still in the air.
          '
          img2={d2} 
          img1={d1}       
          />
          <DestinationData
          className="first-des"
          text='
         
          
          
          
         
          Do I Need a Drone Survey?
          You might be wondering if a drone is better than those traditional methods. There are many ways that they are more beneficial. These drones can acquire data at vantage points that are not accessible to humans, especially those areas with challenging terrains. There is no need for human operators to measure points in hazardous locations physically. However, those traditional methods do require pre-planning before reaching the site. When a drone flies over an area, it can capture the same amount of data in a shorter time frame. Drone surveying allows the surveyor to collect data more safely and quickly than those traditional methods. If you are looking for a more efficient way to survey those areas, it might be time to consider using a drone survey. 
          
          How Accurate Is a Drone Survey?
          If you are concerned about the accuracy of a drone survey, these units are incredibly accurate. In many cases, you can expect drone surveys to come within two centimeters of the actual location. Most drones are even more accurate than those measurements. When you work with a professional drone surveying company, you can expect these types of results. Remember that they have access to better equipment and that always leads to more accurate outcomes. Plus, the kind of drone, quality of the camera, flight height, and ground cover can all influence the survey results.
          
         '
          img1={d3}
          img2={d4}
          />
    </div>
    </>
  )
}

export default DroneSurvey