import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Contactimg from "../assets/dronepic.jpg";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import Whatsapp from "../components/Whatsapp";
import ContactForm2 from "../components/ContactForm2";
function Contact() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-mid" heroimg={Contactimg} title="" btnClass="hide" />
      <ContactForm2 />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default Contact;
