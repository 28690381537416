export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-house"
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  {
    title: "Services",
    url: "/service",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase"
  },
  {
    title: "Gallery",
    url: "/gallery",
    cName: "nav-links",
    icon: "fa-sharp fa-solid fa-image"
  },
  {
    title: "Blog",
    url: "/blog",
    cName: "nav-links",
    icon: "fa-sharp fa-solid fa-newspaper"
  },
  {
    title: "Contact Us",
    url: "/contact",
    cName: "nav-links",
    icon: "fa-solid fa-address-book"
  },
  {
    title: "Resource Persons",
    url: "/resources",
    cName: "nav-links",
    icon: "fa-solid fa-user"
  }
//  {
  //  title: "Plots",
    //url: "/plots",
    //cName: "nav-links-mobile"
  //}
];

// export const MenuItems = [
//   {
//     title: "Home",
//     url: "/",
//     cName: "nav-links",
//     icon: "fa-solid fa-house"
//   },
//   {
//     title: "About",
//     url: "/about",
//     cName: "nav-links",
//     icon: "fa-solid fa-circle-info"
//   },
//   {
//     title: "Services",
//     url: "/services",
//     cName: "nav-links",
//     icon: "fa-solid fa-briefcase",
//     subItems: [
//       {
//         title: "Page A",
//         url: "/services/a",
//         cName: "dropdown-link"
//       },
//       {
//         title: "Page B",
//         url: "/services/b",
//         cName: "dropdown-link"
//       },
//       {
//         title: "Page C",
//         url: "/services/c",
//         cName: "dropdown-link"
//       },
//       {
//         title: "Page D",
//         url: "/services/d",
//         cName: "dropdown-link"
//       },
//       {
//         title: "Page E",
//         url: "/services/e",
//         cName: "dropdown-link"
//       }
//     ]
//   },
//   {
//     title: "Gallery",
//     url: "/gallery",
//     cName: "nav-links",
//     icon: "fa-solid fa-address-book"
//   },
//   {
//     title: "Sign Up",
//     url: "/signup",
//     cName: "nav-links-mobile"
//   }
// ];
