import "./ContactFormStyles.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s34bovh",
        "template_4vd9pxn",
        form.current,
        "IGAS6jNY5Zm55sK9I"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    // <div className="from-container">
    //   <h1>Send a message to us!</h1>
    //   <form>
    //     <input placeholder="Name" />
    //     <input placeholder="Email" />
    //     <input placeholder="Subject" />
    //     <textarea placeholder="Message" rows="4"></textarea>
    //     <button>Send</button>
    //   </form>
    // </div>
    <>
      <h1>Please send a message to us!</h1>
      <div className="from-container">
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" className="buttons" />
        </form>
      </div>
    </>
  );
}

export default ContactForm;
