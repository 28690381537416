import React from "react";
import Kl from "../assets/klogo.jpg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
const Whatsapp = () => {
  return (
    <>
      <FloatingWhatsApp
        phoneNumber="+919801393939"
        accountName="Kraas"
        avatar={Kl}
      />
      <h1>{/*use https://onboarding.brevo.com/ for api to send message */}</h1>
    </>
  );
};
export default Whatsapp;
