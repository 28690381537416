import React from 'react'
import DestinationData from '../components/DestinationData'
import ppk1 from '../assets/ppk1.jpg';
import ppk2 from '../assets/ppk2.png';
import ppk3 from '../assets/ppk3.jpg';
import d4 from '../assets/d4.jpg';
const PPK = () => {
  return (
    <>
    <div className='destination'>
    <DestinationData
          className="first-des"
          heading="Post Processed Kinematic (PPK) Survey
      "
          text='Post-Processed Kinematic (PPK) is a GPS correction technology extensively used in surveying to enhance the accuracy of location data. Unlike real-time corrections provided by RTK, PPK corrects the data after it is collected and uploaded. PPK techniques are commonly employed in drone mapping and land surveying applications.

          In PPK surveying, at least two Global Navigation Satellite System (GNSS) receiver units are utilized to achieve centimeter-level measurement accuracy. One unit serves as the static reference receiver known as the "base," while the other unit functions as the moving receiver referred to as the "rover." Multiple receivers can be employed in this surveying technique.
          
          During the workflow, both the base and rover units record raw GNSS data, which consists of unprocessed observations. There is no requirement for a stable connection between the rover and the base station during data collection. The collected data is stored in a survey controller or recipient until the fieldwork is completed, and later it is processed using software that applies the necessary corrections to the data.
          '
          img2={ppk2} 
          img1={ppk1}       
          />
          <DestinationData
          className="first-des-reverse"
          text='
          In modern drone mapping technologies, PPK-enabled drones act as receivers and capture numerous geotagged images of areas during multiple passes. PPK surveying offers several advantages, including reducing the reliance on ground control points (GCP) and saving time in field preparation. It allows for longer baselines of up to 100 km and is suitable for surveying areas with obstructions like trees or buildings. PPK surveys are particularly useful for tasks that require precise measurements, such as recording sample site locations, mapping shorelines, or determining the positions of markers.

          Overall, PPK surveying provides accurate location information and presents a viable alternative to traditional surveying methods. By utilizing PPK techniques, surveyors can achieve high precision and efficiency in mapping and land surveying projects.
         '
          img1={ppk3}
          img2={d4}
          />
    </div>
    </>
  )
}

export default PPK