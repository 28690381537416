import React from 'react'
import DestinationData from '../components/DestinationData'
import DGPS1 from '../assets/DGPS1.jpg';
import DGPS2 from '../assets/DGPS2.jpg';
const DGPS = () => {
  return (
    <>
    <div className='destination'>
    <DestinationData
          className="first-des"
          heading="KRAAS DGPS Survey
      "
          text="A precise land survey is the first step of any construction project and the accuracy of this survey will define the precision of the construction going forward.

          Also, there are various methods that surveyors have used for many years e.g. Total Station. So, the survey equipment and technology keep upgrading every year.
          
          DGPS or Differential Global Positioning Technology is one such technology that is being used extensively by surveyors across the globe.                        How does DGPS work?
          
          A Differential Global Positioning System (DGPS) is an upgrade of the Global Positioning System (GPS). It provided improved location accuracy from about 15 meters to a few centimeters.
          Each DGPS uses a network of fixed ground-based reference stations to broadcast the difference between the positions indicated by the GPS satellite system and known fixed positions.
          So, these stations broadcast the difference between the measured satellite pseudo ranges and actual (internally computed) pseudo ranges, and receiver stations may correct their pseudo ranges by the same amount.
          Also, the digital correction signal is typically broadcast locally over ground-based transmitters of shorter range.
          Benefits of DGPS over Total Station Survey
          Total station survey has constraints as it cannot be done when there is a lot of thick vegetation as there is no line of sight at times.
          Terrain is not a constraint.
          X,Y,Z accuracy within millimeters
          Faster work completion as compared to total station
          Used to improve the accuracy of drone surveys"
          
          img1={DGPS1}        
          img2={DGPS2}        
          />
    </div>
    </>
  )
}

export default DGPS