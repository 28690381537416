import React from 'react'
import DestinationData from '../components/DestinationData'
import d1 from '../assets/drone1.jpg';
import d2 from '../assets/dronepic.jpg';
import a1 from '../assets/service1.jpg';
import a2 from '../assets/klogo1.jpg';
const PLS = () => {
  return (
    <>
    <div className='destination'>
    <DestinationData
          className="first-des"
          heading="PROFESSIONAL LAND SURVEYING :KRAAS (P) Ltd.
      "
          text='     
          We are land surveying firm, providing services across the Maharashtra.
          
          We specialize in construction layouts, topographical surveys, boundaries, subdivision design, title and boundary surveys, pipelines, unit surveys, digitizing, map reproduction and preparation, contour surceyand provide dedicated mapping services.
          
          Professional Land Surveying Services
          Whether a project involves surveying hundreds of miles of lands, our survey team is more than prepared to tackle any challenge. Integrating our newly refined data communications network, high quality instruments, and professional staff, our land survey services can be an efficient solution to every project. If you need a professional land surveyor, then look no further.
          
          Construction Staking,
          Unitization Surveys,
          Boundary Surveys,
          Utility Routes,
          Subdivision Staking,
          Land Partitions,
          Pipeline Right-Of-Way Staking,
          Topographic Surveys,
          Corridor Route Surveys ,     Layout Surveys,
          Road Layout Surveys ,           Land acquisition surveys  ,    Land Survey solutions.
          '
          img2={a1} 
          img1={a2}       
          />
    
    </div>
    </>
  )
}

export default PLS