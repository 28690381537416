import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import "./ContactForm2Styles.css"
const ContactForm2 = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    const serviceId = 'service_j76us36';
    const templateId = 'template_igrtcon';
    const userId = 'tTTCBud1oRthgHIAt';

    // Send the message using EmailJS
    emailjs.send(serviceId, templateId, formData, userId)
      .then(() => {
        // Reset form data
        setFormData({
          name: '',
          email: '',
          message: '',
        });

        // Display success message and redirect to the home page
        alert('Message sent successfully');
        // Replace '/home' with your desired home page URL
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        // Handle error as needed
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Contact Us</h2>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <button type="submit">Send</button>
    </form>
  );
};

export default ContactForm2;
