import TripData from "./TripData";
import "./TripStyles.css";
import Trip1 from "../assets/5.jpg";
import Trip2 from "../assets/6.jpg";
import Trip3 from "../assets/4.jpg";
function Trip() {
  return (
    <div className="trip">
      <h1>Recent Trips</h1>
      <p>You can discover the trips using google maps</p>
      <div className="tripCard">
        <TripData
          image={Trip1}
          heading="Trip in Indonesia"
          text="eniurnufibsdlbcjkxnmfosaifndklmc,iaoerjf oijdisal oaepjd osejfdpoj"
        />
        <TripData
          image={Trip2}
          heading="Trip in Bali"
          text="eniurnufibsdlbcjkxnmfosaifndklmc,iaoerjf oijdisal oaepjd osejfdpoj"
        />
        <TripData
          image={Trip3}
          heading="Trip in Thailand"
          text="eniurnufibsdlbcjkxnmfosaifndklmc,iaoerjf oijdisal oaepjd osejfdpoj"
        />
      </div>
    </div>
  );
}
export default Trip;
