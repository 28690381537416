import React from 'react'
import "./ResourceData.css";
import va from '../assets/va.jpg'
import rp2 from '../assets/rp2.jpg'
import rp3 from '../assets/rp3.jpg'
import rp4 from '../assets/rp4.jpg'
import rp5 from '../assets/rp5.jpg'
import white from '../assets/blankimg.png'
import TripData from './TripData'

import DestinationData from './DestinationData'
import Card from './Card'
const ResourceData = () => {
  return (<>
    <div className='tripCard'>
    <TripData
    image={white}
    heading="Vinayak Awatade"
    heading2="Surveying Expert"
    />
    <TripData
    image={white}
    heading="Datta Ghule"
    heading2="Diploma in Surveying"
    />
    <TripData
    image={white}
    heading="Dhanraj Awatade"
    heading2=" BE (textiles)"
    />
    <TripData
    image={white}
    heading="Vishwajit Awatade"
    heading2='Diploma in Civil Engineering'
    />
    <TripData
    image={white}
    heading="Sayyad Shekh"
    heading2="BSC (Chemistry)"
    />
    </div>
</>
  )
}

export default ResourceData




// <DestinationData
// className="first-des-reverse"
// heading=""
// img2={va}
// img1=""
// />

// <ImageWithBoldName
// className="first-des-reverse"
// heading="Dhanraj Awatade"
// img2={rp3}
// img1={rp5}
// />
// <ImageWithBoldName
// className="first-des-reverse"
// heading="Vishwajit Awatade"
// img2={rp4}
// img1={rp5}
// />
// <ImageWithBoldName
// className="first-des-reverse"
// heading="Sayyad Shekh"
// img2={rp5}
// img1={rp5}
// />
// <TripData
// image={rp2}
// heading="
// Datta Ghule
// "
// heading2=" "

// />
// <TripData
// image={rp2}
// heading="
// Datta Ghule
// "
// heading2=" "

// />
// <TripData
// image={rp2}
// heading="
// Datta Ghule
// "
// heading2=" "

// />
// <TripData
// image={rp2}
// heading="
// Datta Ghule
// "
// heading2=" "

// />
// <TripData
// image={va}
// heading="
// Vinayak Awatade
// "
// heading2=" "

// />
// </>  