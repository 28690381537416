import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Serviceimg from "../assets/service1.jpg";
import Footer from "../components/Footer";
import Trip from "../components/Trip";
import Servicepage from "../components/Servicepage";
import Whatsapp from "../components/Whatsapp";

function Service() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroimg={Serviceimg}
        title="Services"
        btnClass="hide"
      />
      <Servicepage />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default Service;
