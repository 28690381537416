import TripData from "./TripData";
import "./TripStyles.css";
import Trip1 from "../assets/blankimg.png";
import Trip2 from "../assets/6.jpg";
import Trip3 from "../assets/4.jpg";
function Team() {
  return (
    <div className="trip">
      <h1>Our Team</h1>
      <p>
        At Kraas, we take pride in our team of highly skilled and experienced
        professionals in the field of surveying. With a wealth of knowledge and
        expertise, our team brings a level of precision and excellence to every
        project we undertake. Our years of hands-on experience have allowed us
        to successfully complete numerous surveying projects, earning a
        reputation for delivering accurate and reliable results. From licensed
        surveyors to dedicated technicians, our cohesive team at Kraas works
        collaboratively to ensure the highest standards of precision and
        efficiency in all our surveying services. When you choose Kraas, you can
        trust that your project is in the hands of a seasoned team that is
        committed to providing exceptional service and meeting your surveying
        needs with expertise and professionalism.
      </p>
      <div className="tripCard">
        <TripData
          image={Trip1}
          heading="KUMUDINEE MAGAR
          "
          heading2="FOUNDER AND MANAGING DIRECTOR "
          text="She holds a Degree in science  from the Shivaji University Kolhapur. She joined hands with Real Estate and Advanced survey with land solutions since 2011. A budding entrepreneur ever since, he has been instrumental in turning around business enormously. In January 2023, She started Kumudinee Realtors & Advanced surveys  Pvt Ltd, currently operational in three major cities across Maharashtra. Embracing the core values of integrity, innovation, transparency, customer centricity, and humility, he finds his greatest bliss in nurturing others and helping them experience their true self and go beyond.  Her contagious enthusiasm and energy engulf his team to be self-driven, which helps them deliver results of quality, not just quantity. .In this journey, she intends to stand to help people achieve their dreams by touching people’s lives in the true sense.She has single-handedly inspired the corporation and dreamt of India’s most trusted Realtor and Advanced survey compony."
        />
        <TripData
          image={Trip1}
          heading="VINAYAK AWATADE
          "
          heading2="DIRECTOR
          "
          text="A Graduate from Solapur University fulfils the role of Director at KRAAS. With a total of 5 years of work experience spanning across several fields including Real Estate & addvanced surveying in search of his inner calling to be an ‘Entrepreneur’ and to fulfil his lifelong dream. He joined hands with our founder to turnaround the real estate business and survey technology in Panvel with his sheer dedication and commitment to the firm's common goal. Apart from these responsibilities, he undertakes great initiatives and persistent efforts towards blooming the young talents at KRAAS.  Taking responsibility of projects and survey work tomely completion and serving selflessly to fulfil the common goals’ are some of the strong characters he exhibits while working."
        />
      </div>
    </div>
  );
}
export default Team;
