import "./styles.css";
import Navbar from "./components/Navbar";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Service from "./routes/Service";
import Plots from "./routes/Plots";
import Gallery from "./routes/Gallery";
import Blog from "./routes/Blog";
import DGPS from "./BlogPages/DGPS";
import DroneSurvey from "./BlogPages/DroneSurvey";
import PLS from "./BlogPages/PLS";
import PPK from "./BlogPages/PPK";
import Resources from "./routes/Resources";
import TotalStation from "./BlogPages/TotalStation";

function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/service" element={<Service />} />
            <Route path="/about" element={<About />} />
            <Route path="/plots" element={<Plots />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/DGPS" element={<DGPS />} />
            <Route path="/blog/DroneSurvey" element={<DroneSurvey />} />
            <Route path="/blog/PLS" element={<PLS />} />
            <Route path="/blog/PPK" element={<PPK />} />
            <Route path="/blog/TotalStation" element={<TotalStation />} />
            <Route path="/resources" element={<Resources />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
