import "./AboutUsStyles.css";
import Team from "./Team";
function AboutUs() {
  return (
    <div className="about-container">
      <h1>Our Story</h1>
      <p>
        KRAAS Pvt.Ltd.company dealing in Real estate and advanced surveying,
        Compony comprises of strong self- driven professionals who focus on the
        development of well-located communities and hustle to create value for
        our buyers and investors. By thoughtful planning and design, we aim to
        create a better future for all involved in our business practices.
        Intent to offer is our core strength and we follow that passionately. We
        are grateful to our business associates and companions for showing their
        continuous trust in our services. KRAAS came into existence in 2023,
        with a vision to help people find their dream PROPERTIES across
        Maharashtra. Our highly experienced team is always curious and attentive
        in delivering a trustworthy experience to our clients, across
        Maharashtra. The idea is to offer a great mix of prompt service and
        investment opportunities to our buyers and investors by acknowledging
        their interests.Our advanced survey technology team completed projects
        with government authorities and private companies. We assurre our
        clients with trustworthy data and land solutios.
      </p>
      <h1>Our Mission</h1>
      <p>
        KRAAS is a STATE LEVEL capable integrated surveying company that
        provides accurate and quality global positioning surveys, property
        boundary relocation, subdivision, topography, structural route alignment
        services for high profile local and international engineering
        consultants and clients. We conduct our business and wok ethics
        responsibly to achieve a superior continuing relationship with our
        employees, customers, and competitors balanced with long term growth to
        fulfill our commitment to the vocation and the community.
      </p>
      <h1>Our Vision</h1>
      <p>
        KRAAS Pvt.Ltd.in the field of Surveying and Real estate, recognized
        throughout the Maharashtra as the leading technical firm made up of the
        finest knowledgeable engineers and associates. We will be the standard
        by which other professional entities measure their performance. Our
        hallmarks will be innovation, initiative, teamwork, and human resource
        excellence of our people. Our ability to anticipate and effectively
        respond to change and technology will be a way of creating opportunities
        to serve best our customers and the public.
      </p>
      <h1> Our Core Values</h1>
      <p>
        Honesty, Integrity, People Teamwork, Technology Environment, Quality
        Safety Progress, Business Relationship
      </p>
      <Team />
    </div>
  );
}
export default AboutUs;
