import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import './Card.css'; // Import the CSS file for styling

const Card = ({ image, title, description, link }) => {
  return (
      <Link to={link} className="card">
      <img src={image} alt="Card" className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
      </div>
    </Link>
  );
};

export default Card;
