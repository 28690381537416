import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Contactimg from "../assets/4.jpg";
import Footer from "../components/Footer";

import Galleryblock from "../components/Galleryblock";
import Whatsapp from "../components/Whatsapp";
function Gallery() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        title="Gallery"
        heroimg={Contactimg}
        btnClass="hide"
      />
      <Galleryblock />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default Gallery;
