import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Aboutimg from "../assets/night.jpg";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";

import Whatsapp from "../components/Whatsapp";
function About() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroimg={Aboutimg}
        title="About Us"
        btnClass="hide"
      />
      <AboutUs />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default About;
