import Mountain1 from "../assets/1.jpg";
import Mountain2 from "../assets/2.jpg";
import Mountain3 from "../assets/3.jpg";
import Mountain4 from "../assets/4.jpg";
import Droneimg from "../assets/dronepic.jpg";
import Drone1 from "../assets/drone1.jpg";
import Survey3 from "../assets/survey3.jpg";
import Survey4 from "../assets/survey4.jpg";
import Survey1 from "../assets/survey1.jpg";
import Survey6 from "../assets/survey6.jpg";
import Survey7 from "../assets/survey7.jpg";
import GPS1 from "../assets/gps1.jpg";
import GPS2 from "../assets/gps2.jpg";
import Basemap1 from "../assets/basemap1.jpeg";
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";
const Servicepage = () => {
  return (
    <>
      <div className="destination">
        <h1>Our Services</h1>
        <p>Kraas provides services such as follows:</p>
        <DestinationData
          className="first-des"
          heading="Land survey with total station
      "
          text="Kraas offers comprehensive land survey services utilizing advanced Total Station technology to provide accurate and detailed survey data. Our team of experienced surveyors utilizes Total Station instruments, which combine electronic theodolite and electronic distance measurement (EDM) capabilities, to capture precise measurements and angles. This allows us to efficiently determine property boundaries, collect topographic data, and create detailed land surveys. Our land survey with Total Station service is suitable for a wide range of applications, including land development, construction, and engineering projects. With our expertise and cutting-edge technology, we ensure that our clients receive reliable and precise survey data for informed decision-making. Trust Kraas for high-quality land surveys with Total Station, providing you with the essential information you need for successful project execution."
          img1={Survey6}
          img2={Survey7}
        />
        <DestinationData
          className="first-des-reverse"
          heading="DGPS/RTK
      "
          text="Kraas specializes in providing advanced DGPS/RTK services, offering clients accurate and reliable positioning solutions for their projects. Our skilled team of surveyors and technicians utilize state-of-the-art DGPS/RTK technology to achieve high-precision positioning results. Whether you require precise location data for construction, infrastructure, or land surveying projects, our DGPS/RTK services ensure centimeter-level accuracy in real-time. By leveraging this advanced positioning technology, we streamline workflows, minimize errors, and enhance overall project efficiency. Our DGPS/RTK services are ideal for applications that demand precise positioning, such as boundary surveys, control point establishment, topographic mapping, and asset management. Trust Kraas to deliver exceptional DGPS/RTK services, enabling you to achieve accurate and reliable positioning data for your projects."
          img1={GPS1}
          img2={GPS2}
        />
        <DestinationData
          className="first-des"
          heading="Base Map survey
      "
          text="Kraas offers comprehensive base map survey services to provide clients with accurate and detailed base maps for a variety of purposes. Our experienced team of surveyors and cartographers utilizes advanced technology and precise surveying techniques to create reliable base maps. These maps serve as a foundation for various projects, including urban planning, infrastructure development, land management, and environmental assessments. Our base map surveys capture essential elements such as property boundaries, topographic features, road networks, water bodies, and landmarks. By leveraging our expertise in base map surveys, clients can make informed decisions, plan effectively, and navigate their projects with confidence. At Kraas, we are committed to delivering high-quality base map surveys that meet the specific needs and requirements of our clients, ensuring that they have the essential information to drive successful outcomes."
          img1={Basemap1}
          img2={Survey1}
        />
        <DestinationData
          className="first-des-reverse"
          heading="UAV / Drone Survey / Mapping
      "
          text="At Kraas, we offer industry-leading UAV drone survey mapping services to provide our clients with accurate and comprehensive aerial data for various applications. Our team of skilled professionals combines the power of advanced drone technology with post-processed kinematic techniques to deliver highly precise and reliable mapping solutions. Equipped with cutting-edge drones and advanced sensors, we capture high-resolution imagery and collect geospatial data with exceptional accuracy. Our UAV drone surveys offer efficient and cost-effective mapping solutions, eliminating the need for extensive ground control points while providing detailed 3D models, orthomosaic maps, and point clouds. Whether you need mapping services for construction, land management, infrastructure inspection, or environmental monitoring, our UAV drone survey mapping services provide you with up-to-date and actionable information for informed decision-making. Trust Kraas to deliver exceptional results and streamline your project workflows with our state-of-the-art UAV drone survey mapping services."
          img1={Droneimg}
          img2={Drone1}
        />

        <DestinationData
          className="first-des"
          heading="Land Acquisition Survey
      "
          text="At Kraas, we specialize in providing comprehensive land acquisition surveys to support your real estate and development projects. Our experienced team of surveyors and land experts utilize advanced equipment and technology to conduct thorough surveys, capturing every detail of the land. Our surveys provide accurate measurements, boundaries, and topographic features, along with detailed reports and maps that offer a clear understanding of the land's suitability for your specific needs. We assess potential legal or environmental constraints, identify encroachments or restrictions, and work closely with legal professionals and authorities to ensure compliance. By utilizing our land acquisition surveys, you can make informed decisions, minimize risks, and streamline the process of acquiring land for your projects, while also avoiding future disputes through precise boundary surveys. Trust Kraas to provide you with reliable data and expert analysis to support your land acquisition endeavors."
          img1={Survey3}
          img2={Survey4}
        />
        <DestinationData
          className="first-des-reverse"
          heading="Land Consultancy
      "
          text="KRAAS is your  perfect consultancy partner, whether you are a landowner looking to sell surplus land, or a residential developer interested in securing a site. We offer specialist consultancy and advice on all areas of land acquisition and purchasing development land.

          The right advice and guidance can make all the difference in achieving a successful land sale for residential development. Even for seasoned industry professionals, this is a highly complex field that must be navigated with tact, diplomacy and a deep understanding of all the pertinent issues in order to effect a positive outcome for all parties concerned."
          img1={Survey3}
          img2={Survey4}
        />
      </div>
    </>
  );
};

export default Servicepage;
