import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Destination from "../components/Destination";
import Trip from "../components/Trip";
import Footer from "../components/Footer";
import Servicepage from "../components/Servicepage";
import Kl from "../assets/klogo.jpg";
import Whatsapp from "../components/Whatsapp";
function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroimg="https://images.unsplash.com/photo-1558864255-eced94afc9ec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=872&q=80"
        title="Your journey , our story"
        text="Interested in plots or land, advanced surveying and land solutions  please write to us.
        "
        buttonText="services"
        url="/service"
        btnClass="show"
      />
      <Servicepage />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default Home;
