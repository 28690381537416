import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Serviceimg from "../assets/5.jpg";
import Footer from "../components/Footer";
import Trip from "../components/Trip";
import Servicepage from "../components/Servicepage";
import PlotPage from "../components/PlotPage";
import Whatsapp from "../components/Whatsapp";

function Plots() {
  return (
    <>
      <Navbar />
      <PlotPage />
      <Whatsapp />
      <Footer />
    </>
  );
}
export default Plots;
